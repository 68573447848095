import './App.css';
import NavBar from './components/layout/NavBar';

function App() {
  return (
    <> {/* React fragment as root */}
    <NavBar />
    <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>
      <header style={{ padding: '20px', backgroundColor: '#282c34', color: 'white' }}>
        <h1>Mendix Freelance Developer</h1>
        <h2>Expert certified</h2>
      </header>

      <main style={{ padding: '20px' }}>
        <h2>Hello! I'm [Your Name]</h2>
        <p>
        I'm a freelance developer specializing in creating robust and intuitive applications using Mendix.
        With extensive experience in low-code development, I can help you bring your ideas to life quickly and efficiently.
        </p>
        <section style={{ margin: '20px 0' }}>
          <h3>Services Offered</h3>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li>💻 Web Development</li>
            <li>📱 Mobile App Development</li>
            <li>🔧 API Integration</li>
            <li>🛠 Maintenance and Support</li>
          </ul>
        </section>
        <section style={{ margin: '20px 0' }}>
          <h3>Contact Me</h3>
          <p>Email: <a href="mailto:your.email@example.com">your.email@example.com</a></p>
          <p>LinkedIn: <a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">yourprofile</a></p>
        </section>
      </main>

      <footer style={{ padding: '10px', backgroundColor: '#282c34', color: 'white', position: 'fixed', width: '100%', bottom: '0' }}>
        <p>&copy; 2024 [Your Name]. All rights reserved.</p>
      </footer>
    </div></>
  );
}

export default App;
